import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Image from 'react-bootstrap/Image';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { FaBell } from 'react-icons/fa';
import './header.css'; // Ensure you have styles in a separate file or include styles here

const Header = () => {
  const navigate = useNavigate();
  const isAuthenticated = !!localStorage.getItem('token'); // Check if user is logged in
  const userEmail = localStorage.getItem('email'); // Get the user email from local storage
  const currentDate = new Date();

  // Format date as "Day Month Year"
  const formattedDate = currentDate.toLocaleDateString('en-US', {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });

  const [rentalsToday, setRentalsToday] = useState([]);
  const [showBackToTop, setShowBackToTop] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false); // New state for dropdown visibility

  const fetchChartData = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch('https://emiruproduction.com/api/rentals/without', {
        headers: { Authorization: `Bearer ${token}` },
      });
  
      if (!response.ok) {
        throw new Error('Failed to fetch rentals');
      }
  
      const { rentals } = await response.json();
      const todayStart = normalizeDateToStartOfDay(new Date());
      const todayEnd = normalizeDateToEndOfDay(new Date());
  
      const filteredRentals = rentals.filter((rental) => {
        const rentalStartDate = new Date(rental.start_date);
        const rentalCreatedAtDate = new Date(rental.created_at);
        
        // Ensure rental start_date is today and created_at is before today
        return (
          rentalStartDate >= todayStart &&
          rentalStartDate <= todayEnd &&
          rentalCreatedAtDate < todayStart
        );
      });
  
      setRentalsToday(filteredRentals);
    } catch (error) {
      console.error('Error fetching rentals:', error);
    }
  };
  
  // Helper function to normalize the date to midnight UTC (start of the day)
  const normalizeDateToStartOfDay = (date) => {
    return new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), 0, 0, 0, 0));
  };
  
  // Helper function to normalize the date to end of the day UTC
  const normalizeDateToEndOfDay = (date) => {
    return new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), 23, 59, 59, 999));
  };
  
  

  useEffect(() => {
    fetchChartData();

    const handleScroll = () => {
      if (window.scrollY > 300) {
        setShowBackToTop(true);
      } else {
        setShowBackToTop(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleLogout = () => {
    // Clear authentication data from local storage
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('email');
    localStorage.removeItem('role');
    // Redirect to login page
    navigate('/login');
  };

  const handleSearch = (e) => {
    e.preventDefault();
    const query = e.target.elements.search.value;
    // Handle search query here
    console.log(query);
    // Redirect or fetch data based on the search query
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  // Group rentals by user_id
  const groupedRentals = rentalsToday.reduce((acc, rental) => {
    const userId = rental.customer.user_id;
    if (!acc[userId]) {
      acc[userId] = [];
    }
    acc[userId].push(rental);
    return acc;
  }, {});

  const handleSeeMoreClick = () => {
    setDropdownOpen(false); // Close the dropdown
    navigate('/listRentcat'); // Navigate to the desired page
  };

  return (
    <>
      {isAuthenticated && (
        <Navbar bg="light" className="nav" variant="light" expand="md">
          <Container>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav
                className="ml-auto d-flex align-items-center"
                style={{ width: '100%', justifyContent: 'space-between' }}
              >
                <div className="date-display" style={{ flex: 1 }}>
                  Today's Date: {formattedDate}
                </div>

                <form
                  onSubmit={handleSearch}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    margin: '0 20px',
                  }}
                >
                  <FormControl
                    type="text"
                    name="search"
                    placeholder="Search..."
                    className="mr-sm-2"
                    style={{ width: '300px' }}
                  />
                  <Button type="submit" variant="outline-success">
                    Search
                  </Button>
                </form>

                <div className="notification-container">
                  <FaBell className="notification-icon" />
                  {rentalsToday.length > 0 && (
                    <span className="notification-badge">
                      {rentalsToday.length}
                    </span>
                  )}
                  <DropdownButton
                    id="notifications-dropdown"
                    title=""
                    align="end"
                    variant="light"
                    style={{ color: 'black', border: 'none' }}
                    show={dropdownOpen} // Control visibility with state
                    onToggle={() => setDropdownOpen(!dropdownOpen)} // Toggle dropdown
                  >
                    {Object.keys(groupedRentals).length === 0 ? (
                      <Dropdown.Item>No new rentals today</Dropdown.Item>
                    ) : (
                      Object.entries(groupedRentals)
                        .slice(0, 2) // Limit the display to the first two users
                        .map(([userId, rentals], index) => (
                          <Dropdown.Item key={index}>
                            <strong>{rentals[0].customer.user_name}</strong>
                            <ul>
                              {rentals.map((rental, i) => (
                                <li key={i}>
                                  {rental.item.item_name} - {rental.quantity} pcs
                                </li>
                              ))}
                            </ul>
                          </Dropdown.Item>
                        ))
                    )}
                    <div
                      className="see-more-container"
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '50px', // You can adjust the height as needed
                        color: 'green',
                        cursor: 'pointer',
                        fontWeight: 'bold',
                        borderTop: '1px solid #ccc', // Optional: adds a separator line above the text
                        marginTop: '5px', // Optional: adds spacing between items and "see more"
                      }}
                      onClick={handleSeeMoreClick}
                    >
                      see more
                    </div>
                  </DropdownButton>
                </div>

                <DropdownButton
                  id="dropdown-basic-button"
                  title={
                    <Image
                      src="/images/admin.png"
                      fluid
                      style={{ maxWidth: '50px', height: '30px' }}
                    />
                  }
                  align="end"
                  variant="light"
                  style={{ color: 'black' }}
                >
                  <Dropdown.Item href="/ListAdmin">Admin List</Dropdown.Item>
                  <Dropdown.Item href="/postAdmin">Admin Post</Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
                </DropdownButton>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      )}

      {showBackToTop && (
        <button onClick={scrollToTop} className="back-to-top">
          <i className="fa fa-arrow-up" aria-hidden="true"></i>
        </button>
      )}
    </>
  );
};

export default Header;
