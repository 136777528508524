import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useLocation, useNavigate } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';

const API_BASE_URL = 'https://emiruproduction.com';

const Success = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { rentalIds = [], totalPrice = 0 } = location.state || {}; // Destructure state properly

  const [paid, setPaid] = useState('');
  const [notPaid, setNotPaid] = useState(totalPrice);
  const [status, setStatus] = useState('pending');
  const [error, setError] = useState(null);

  // Generate category name based on totalPrice and rentalIds
  const categoryName = `Category-${totalPrice}-${rentalIds.join('-')}`;

  // Update notPaid whenever paid changes
  useEffect(() => {
    setNotPaid(totalPrice - (parseFloat(paid) || 0));
  }, [paid, totalPrice]);

  const handleCategorize = async () => {
    try {
      const paidAmount = parseFloat(paid) || 0;
      if (paidAmount > totalPrice) {
        throw new Error('Paid amount cannot exceed total price');
      }

      const token = localStorage.getItem('token');
      const response = await fetch(`https://emiruproduction.com/api/rental-categories/rental-categories/categorize`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
        body: JSON.stringify({
          rentalIds,
          category_name: categoryName, // Use generated category name
          total_price: totalPrice,
          paid: paidAmount,
          not_paid: notPaid,
          status,
        })
      });

      if (response.ok) {
        alert(' successfull');
        navigate('/'); // Navigate to the dashboard or another page as needed
      } else {
        const data = await response.json();
        throw new Error(data.message || 'Failed to categorize rentals');
      }
    } catch (error) {
      console.error('Error:', error);
      setError(error.message);
    }
  };

  return (
    <Container className="mt-5">
      <Row className="justify-content-center">
        <Col md={8}>
          <Card>
            <Card.Header as="h3" className="text-center">Payment page</Card.Header>
            <Card.Body>
              
              <Card.Text className="text-center">
                
              </Card.Text>
              <Card.Text className="text-center">
          
              </Card.Text>
              

              <Form>
                <Form.Group controlId="formPaid" className="mt-3">
                  <Form.Label>Paid Amount</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Enter paid amount"
                    value={paid}
                    onChange={(e) => setPaid(e.target.value)}
                  />
                </Form.Group>

                <Form.Group controlId="formNotPaid" className="mt-3">
                  <Form.Label>Not Paid Amount</Form.Label>
                  <Form.Control
                    type="number"
                    value={notPaid}
                    readOnly
                  />
                </Form.Group>

                <Form.Group controlId="formStatus" className="mt-3">
                  <Form.Label>Status</Form.Label>
                  <Form.Control
                    as="select"
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                  >
                    <option value="pending">Pending</option>
                 
                  </Form.Control>
                </Form.Group>

                <Button variant="primary" onClick={handleCategorize} className="mt-4 w-100">
                  Finish
                </Button>
              </Form>
              {error && <p className="text-danger text-center mt-3">{error}</p>}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Success;
