import React, { useState, useEffect } from "react";
import { Bar, Pie } from "react-chartjs-2";
import Card from "react-bootstrap/Card";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  BarController,
} from "chart.js";
import "./dashboard.css";

// Register Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  BarController
);

const Dashboard = () => {
  const isAuthenticated = !!localStorage.getItem("token");
  const userEmail = localStorage.getItem("email");

  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        label: "Item Quantities",
        data: [],
        backgroundColor: [
          "rgba(255, 165, 0, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "rgba(255, 165, 0, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
  });

  const [customerPieData, setCustomerPieData] = useState({
    labels: [],
    datasets: [
      {
        label: "Customer Rentals Today",
        data: [],
        backgroundColor: [
          "rgba(75, 192, 192, 0.2)",
          "rgba(75, 192, 192, 0.3)",
          "rgba(75, 192, 192, 0.4)",
          "rgba(75, 192, 192, 0.5)",
          "rgba(75, 192, 192, 0.6)",
          "rgba(75, 192, 192, 0.7)",
        ],
        borderColor: [
          "rgba(75, 192, 192, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(75, 192, 192, 1)",
        ],
        borderWidth: 1,
      },
    ],
  });

  const [rentalBarData, setRentalBarData] = useState({
    labels: [],
    datasets: [
      {
        label: "Rentals Over Time",
        data: [],
        backgroundColor: [
          "rgba(54, 162, 235, 0.2)",
          "rgba(54, 162, 235, 0.3)",
          "rgba(54, 162, 235, 0.4)",
          "rgba(54, 162, 235, 0.5)",
          "rgba(54, 162, 235, 0.6)",
          "rgba(54, 162, 235, 0.7)",
        ],
        borderColor: [
          "rgba(54, 162, 235, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(54, 162, 235, 1)",
        ],
        borderWidth: 2,
        fill: true,
      },
    ],
  });

  const [totalQuantities, setTotalQuantities] = useState(0);
  const [totalRentalsToday, setTotalRentalsToday] = useState(0);
  const [totalCustomersToday, setTotalCustomersToday] = useState(0);

  const fetchChartData = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch("https://emiruproduction.com/api/rentals/without", {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (!response.ok) {
        throw new Error("Failed to fetch rentals");
      }

      const { rentals } = await response.json();

      const today = new Date().toISOString().split("T")[0];

      const filteredRentals = rentals.filter((rental) => {
        const rentalStartDate = new Date(rental.start_date)
          .toISOString()
          .split("T")[0];
        return rentalStartDate === today;
      });

      const itemQuantities = {};
      filteredRentals.forEach((rental) => {
        const itemName = rental.item.item_name;
        itemQuantities[itemName] = (itemQuantities[itemName] || 0) + rental.quantity;
      });

      const itemNames = Object.keys(itemQuantities);
      const quantities = Object.values(itemQuantities);
      const totalQuantity = quantities.reduce((acc, curr) => acc + curr, 0);

      setTotalQuantities(totalQuantity);

      setChartData({
        labels: itemNames,
        datasets: [
          {
            label: "Item Quantities",
            data: quantities,
            backgroundColor: itemNames.map(() => "rgba(255, 165, 0, 0.2)"),
            borderColor: itemNames.map(() => "rgba(255, 165, 0, 1)"),
            borderWidth: 1,
          },
        ],
      });

      const totalRentals = filteredRentals.length;
      setTotalRentalsToday(totalRentals);

      const uniqueCustomersToday = new Set(
        filteredRentals.map((rental) => rental.customer.user_id)
      ).size;

      setTotalCustomersToday(uniqueCustomersToday);

      const customerNames = {};
      filteredRentals.forEach((rental) => {
        const userId = rental.customer.user_id;
        const userName = rental.customer.user_name; // Assuming user_name field is available
        customerNames[userId] = userName;
      });

      const customerRentals = {};
      filteredRentals.forEach((rental) => {
        const userId = rental.customer.user_id;
        customerRentals[userId] = (customerRentals[userId] || 0) + 1;
      });

      const customerIds = Object.keys(customerRentals);
      const rentalCounts = Object.values(customerRentals);

      setCustomerPieData({
        labels: customerIds.map((id) => customerNames[id] || `User ${id}`),
        datasets: [
          {
            label: "Customer Rentals Today",
            data: rentalCounts,
            backgroundColor: customerIds.map(() => "rgba(75, 192, 192, 0.2)"),
            borderColor: customerIds.map(() => "rgba(75, 192, 192, 1)"),
          },
        ],
      });

      const rentalCountsByHour = {};
      filteredRentals.forEach((rental) => {
        const hour = new Date(rental.start_date).getHours();
        rentalCountsByHour[hour] = (rentalCountsByHour[hour] || 0) + 1;
      });

      const hours = Array.from({ length: 24 }, (_, i) => i);
      const rentalsPerHour = hours.map((hour) => rentalCountsByHour[hour] || 0);

      setRentalBarData({
        labels: hours.map((hour) => {
          const formattedHour = hour % 12 || 12;
          const period = hour < 12 ? "AM" : "PM";
          return `${formattedHour}:00 ${period}`;
        }),
        datasets: [
          {
            label: "Rentals Over Time",
            data: rentalsPerHour,
            backgroundColor: "rgba(54, 162, 235, 0.2)",
            borderColor: "rgba(54, 162, 235, 1)",
            borderWidth: 2,
            fill: true,
          },
        ],
      });
    } catch (error) {
      console.error("Error fetching rentals:", error);
    }
  };

  useEffect(() => {
    fetchChartData();
  }, []);

  return (
    <div className="app bg-red">
      <div className="container mt-5">
        {isAuthenticated && (
          <div className="welcome-message">
            Welcome, {userEmail}!
          </div>
        )}
        <div className="dashboard">
          <h1 className="mb-4">Dashboard</h1>
          <div className="container">
            <div className="row">
              <div className="col-md-4">
                <Card className="bg-light">
                  <Card.Body>
                    <i
                      className="fas fa-box fa-3x mb-3"
                      style={{ color: "#4CAF50" }}
                    ></i>
                    <Card.Title>Total Items out today</Card.Title>
                    <Card.Text>{totalQuantities}</Card.Text>
                  </Card.Body>
                </Card>
              </div>
              <div className="col-md-4">
                <Card className="bg-light">
                  <Card.Body>
                    <i
                      className="fas fa-users fa-3x mb-3"
                      style={{ color: "#2196F3" }}
                    ></i>
                    <Card.Title>Total Customers Today</Card.Title>
                    <Card.Text>{totalCustomersToday}</Card.Text>
                  </Card.Body>
                </Card>
              </div>
              <div className="col-md-4">
                <Card className="bg-light">
                  <Card.Body>
                    <i
                      className="fas fa-box-open fa-3x mb-3"
                      style={{ color: "#FF9800" }}
                    ></i>
                    <Card.Title>Total Rentals Today</Card.Title>
                    <Card.Text>{totalRentalsToday}</Card.Text>
                  </Card.Body>
                </Card>
              </div>
            </div>

            <div className="row mt-5">
              <div className="col-md-6 mb-4">
                <h4 className="mb-4">Items Chart</h4>
                <Bar
                  data={chartData}
                  options={{
                    responsive: true,
                    plugins: {
                      legend: {
                        display: true,
                        position: "top",
                      },
                      title: {
                        display: true,
                        text: "Item Quantities",
                      },
                    },
                  }}
                />
              </div>

              <div className="col-md-6 mb-4">
                <h4 className="mb-4">Customer Rentals Today</h4>
                <Pie
                  data={customerPieData}
                  options={{
                    responsive: true,
                    plugins: {
                      legend: {
                        display: true,
                        position: "right",
                      },
                      title: {
                        display: true,
                        text: "Customer Rentals",
                      },
                    },
                  }}
                />
              </div>
            </div>

            <div className="row mt-5">
              <div className="col-md-12 mb-4">
                <h4 className="mb-4">Rentals Over Time</h4>
                <Bar
                  data={rentalBarData}
                  options={{
                    responsive: true,
                    plugins: {
                      legend: {
                        display: true,
                        position: "top",
                      },
                      title: {
                        display: true,
                        text: "Rentals Over Time",
                      },
                    },
                    scales: {
                      x: {
                        grid: {
                          display: false,
                        },
                      },
                      y: {
                        grid: {
                          display: true,
                        },
                        beginAtZero: true,
                      },
                    },
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
