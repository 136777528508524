// src/pages/WelcomePage.js
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const WelcomePage = () => {
  const navigate = useNavigate();
  const [role, setRole] = useState(null);
  const email = localStorage.getItem('email');

  useEffect(() => {
    const token = localStorage.getItem('token');
    const userRole = localStorage.getItem('role');
    
    if (!token) {
      // Redirect to login if no token is found
      navigate('/login');
    } else {
      setRole(userRole);
    }
  }, [navigate]);

 

  if (role === 'ADMIN') {
    return (
      <div>
        <h1>Welcome!</h1>
        <p>Hello, {email || 'Admin'}! You have successfully logged in.</p>
      </div>
    );
  } else if (role === 'USER') {
    return (
      <div>
        <h1>Access Denied</h1>
        <p>You have no access to this page.</p>
      </div>
    );
  } else {
    return (
      <div>
        <h1>Loading...</h1>
      </div>
    );
  }
};

export default WelcomePage;
