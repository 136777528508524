import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom

const No_Match = () => {
  return (
    <>
      <div className="d-flex justify-content-center align-items-center" id="main">
        <img src="/images/404.gif" alt="404 Error" style={{ width: '400px', height: '300px' }} />
        <div className="ml-4">
          <h1 className="mr-3 pr-3 align-top border-right inline-block align-content-center">Opps...</h1>
          <div className="inline-block align-middle">
            <h2 className="font-weight-normal lead" id="desc">The page you requested was not found.</h2>
            {/* Button to go to Home Page */}
            <Link to="/" className="btn btn-secondary mt-3">Go to Home Page</Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default No_Match;
