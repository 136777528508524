import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Table from 'react-bootstrap/Table';
import Container from 'react-bootstrap/Container';
import Pagination from 'react-bootstrap/Pagination';
import Button from 'react-bootstrap/Button';
import { useNavigate } from 'react-router-dom';
import { Alert, Spinner, Modal, Form } from 'react-bootstrap';

function ListItems() {
  const [items, setItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5);
  const [totalPages, setTotalPages] = useState(1);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [modalMode, setModalMode] = useState('update'); // 'update' or 'delete'
  const [selectedItem, setSelectedItem] = useState(null);
  const [formData, setFormData] = useState({ item_name: '', item_price: '', item_description: '', item_quantity: '' });
  const [file, setFile] = useState(null); // New state for file upload
  const navigate = useNavigate();

  useEffect(() => {
    const checkAuthorization = () => {
      const token = localStorage.getItem('token');
      const role = localStorage.getItem('role');
      
      if (token) {
        setIsAuthorized(true);
      } else {
        setIsAuthorized(false);
        navigate('/login');
      }
    };

    checkAuthorization();
  }, [navigate]);

  useEffect(() => {
    if (isAuthorized) {
      fetchItems(currentPage, itemsPerPage);
    }
  }, [currentPage, itemsPerPage, searchKeyword, isAuthorized]);

  const fetchItems = async (page, size) => {
    setLoading(true);
    try {
      const token = localStorage.getItem('token');
      let url = `https://emiruproduction.com/api/items?page=${page}&size=${size}`;
      if (searchKeyword) {
        url += `&search=${searchKeyword}`;
      }

      const response = await axios.get(url, {
        headers: { Authorization: `Bearer ${token}` }
      });

      setItems(response.data.items);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      if (error.response) {
        const { status, data } = error.response;
        if (status === 500) {
          setError(data.message || 'An unexpected error occurred. Please try again.');
        } else {
          setError('Failed to fetch items. Please try again.');
        }
      } else {
        setError('Network error. Please check your connection.');
      }
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = () => {
    setCurrentPage(1);
    fetchItems(1, itemsPerPage);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    fetchItems(pageNumber, itemsPerPage);
  };

  const handleShowModal = (mode, item) => {
    setModalMode(mode);
    setSelectedItem(item);
    setFormData({
      item_name: item.item_name,
      item_price: item.item_price,
      item_description: item.item_description,
      item_quantity: item.item_quantity || '' // Include quantity in the form data
    });
    setFile(null); // Reset file state
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedItem(null);
    setFile(null); // Reset file state
  };

  const handleFormChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleUpdate = async () => {
    try {
      const token = localStorage.getItem('token');
      const formDataToSend = new FormData();

      formDataToSend.append('item_name', formData.item_name);
      formDataToSend.append('item_price', formData.item_price);
      formDataToSend.append('item_description', formData.item_description);
      formDataToSend.append('item_quantity', formData.item_quantity);
      if (file) {
        formDataToSend.append('file', file);
      }

      await axios.put(`https://emiruproduction.com/api/items/${selectedItem.item_id}`, formDataToSend, {
        headers: { 
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data'
        }
      });
      fetchItems(currentPage, itemsPerPage);
      handleCloseModal();
    } catch (error) {
      setError('Failed to update item. Please try again.');
    }
  };

  const handleDelete = async () => {
    try {
      const token = localStorage.getItem('token');
      await axios.delete(`https://emiruproduction.com/api/items/${selectedItem.item_id}`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      fetchItems(currentPage, itemsPerPage);
      handleCloseModal();
    } catch (error) {
      setError('Failed to delete item. Please try again.');
    }
  };

  if (!isAuthorized) {
    return null; // Or a loading spinner if you want to show a spinner while checking authorization
  }

  return (
    <Container>
      <h5>Item List</h5>
      <div className="mb-3 d-flex flex-column align-items-start">
        <input
          type="text"
          placeholder="Search by name"
          value={searchKeyword}
          onChange={(e) => setSearchKeyword(e.target.value)}
          className="form-control mb-2"
        />
        <Button onClick={handleSearch} className="mb-2">Search</Button>
      </div>
      {error && <Alert variant="danger" className="new-alert">{error}</Alert>}
      {loading ? <Spinner animation="border" size="sm" /> : (
        <>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Item Name</th>
                <th>Item Price</th>
                <th>Item Description</th>
                <th>Item Quantity</th> {/* New Quantity column */}
                <th>Image</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {items.length > 0 ? items.map(item => {
                // Safely handle null or undefined file_path
                const imageUrl = item.file_path ? 
                  `https://emiruproduction.com/${item.file_path.replace('/home/emirupzd/public_html/', '').replace(/\\/g, '/')}` 
                  : 'path_to_placeholder_image'; // Replace with a placeholder image URL or text

                return (
                  <tr key={item.item_id}>
                    <td>{item.item_name}</td>
                    <td>${item.item_price || 'N/A'}</td>
                    <td>{item.item_description}</td>
                    <td>{item.item_quantity || 'N/A'}</td>
                    <td>
                      {item.file_path ? (
                        <img 
                          src={imageUrl} 
                          alt={item.item_name}
                          style={{ width: '150px', height: 'auto' }} 
                        />
                      ) : (
                        'No image available'
                      )}
                    </td>
                    <td>
                      
                      <Button variant="warning" onClick={() => handleShowModal('update', item)} className="me-2">Update</Button>
                      <Button variant="danger" onClick={() => handleShowModal('delete', item)}>Delete</Button>
                    </td>
                  </tr>
                );
              }) : (
                <tr>
                  <td colSpan="6" className="text-center">No items found</td>
                </tr>
              )}
            </tbody>
          </Table>
          <div className="d-flex justify-content-center mt-3">
            <Pagination>
              <Pagination.Prev 
                onClick={() => currentPage > 1 && handlePageChange(currentPage - 1)} 
                disabled={currentPage === 1} 
              />
              {Array.from({ length: totalPages }, (_, index) => (
                <Pagination.Item
                  key={index + 1}
                  active={index + 1 === currentPage}
                  onClick={() => handlePageChange(index + 1)}
                >
                  {index + 1}
                </Pagination.Item>
              ))}
              <Pagination.Next 
                onClick={() => currentPage < totalPages && handlePageChange(currentPage + 1)} 
                disabled={currentPage === totalPages} 
              />
            </Pagination>
          </div>
        </>
      )}

      {/* Modal for Update/Delete */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>{modalMode === 'update' ? 'Update Item' : 'Delete Item'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {modalMode === 'update' ? (
            <Form>
              <Form.Group controlId="itemName">
                <Form.Label>Item Name</Form.Label>
                <Form.Control 
                  type="text" 
                  name="item_name"
                  value={formData.item_name}
                  onChange={handleFormChange}
                  placeholder="Enter item name" 
                />
              </Form.Group>
              <Form.Group controlId="itemPrice">
                <Form.Label>Item Price</Form.Label>
                <Form.Control 
                  type="number" 
                  name="item_price"
                  value={formData.item_price}
                  onChange={handleFormChange}
                  placeholder="Enter item price" 
                />
              </Form.Group>
              <Form.Group controlId="itemDescription">
                <Form.Label>Item Description</Form.Label>
                <Form.Control 
                  as="textarea" 
                  name="item_description"
                  value={formData.item_description}
                  onChange={handleFormChange}
                  placeholder="Enter item description" 
                />
              </Form.Group>
              <Form.Group controlId="itemQuantity">
                <Form.Label>Item Quantity</Form.Label>
                <Form.Control 
                  type="number" 
                  name="item_quantity"
                  value={formData.item_quantity}
                  onChange={handleFormChange}
                  placeholder="Enter item quantity" 
                />
              </Form.Group>
              <Form.Group controlId="itemImage">
                <Form.Label>Item Image</Form.Label>
                <Form.Control 
                  type="file"
                  onChange={handleFileChange} 
                />
              </Form.Group>
            </Form>
          ) : (
            <p>Are you sure you want to delete this item?</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>Close</Button>
          {modalMode === 'update' ? (
            <Button variant="primary" onClick={handleUpdate}>Update</Button>
          ) : (
            <Button variant="danger" onClick={handleDelete}>Delete</Button>
          )}
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default ListItems;
