import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Typeahead } from 'react-bootstrap-typeahead';
import { useNavigate } from 'react-router-dom';
import './RentItem.css';

const API_BASE_URL = 'https://emiruproduction.com';

const ITEMS_PER_PAGE = 4;
const CUSTOMERS_PER_PAGE = 4;

const RentItem = () => {
    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleDateString('en-US', {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    });

    const [items, setItems] = useState([]);
    const [users, setUsers] = useState([]);
    const [rentals, setRentals] = useState([{ itemId: '', quantity: 1, startDate: '', endDate: '', price: '', availability: '' }]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [error, setError] = useState(null);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [isAuthorized, setIsAuthorized] = useState(false);
    const [itemsPage, setItemsPage] = useState(1);
    const [customersPage, setCustomersPage] = useState(1);
    const [totalItemsPages, setTotalItemsPages] = useState(0);
    const [totalCustomersPages, setTotalCustomersPages] = useState(0);
    const navigate = useNavigate();

    useEffect(() => {
        const checkAuthorization = () => {
            const role = localStorage.getItem('role');
            const token = localStorage.getItem('token');
            if (token) {
                setIsAuthorized(true);
            } else {
                setIsAuthorized(false);
                navigate('/unauthorized');
            }
        };

        checkAuthorization();
    }, [navigate]);

    useEffect(() => {
        if (isAuthorized) {
            const fetchItemsAndUsers = async () => {
                try {
                    const token = localStorage.getItem('token');
                    const [itemsResponse, usersResponse] = await Promise.all([
                        fetch(`${API_BASE_URL}/api/items?page=${itemsPage}&size=${ITEMS_PER_PAGE}`, {
                            headers: { Authorization: `Bearer ${token}` }
                        }),
                        fetch(`${API_BASE_URL}/api/customers?page=${customersPage}&size=${CUSTOMERS_PER_PAGE}`, {
                            headers: { Authorization: `Bearer ${token}` }
                        })
                    ]);

                    if (itemsResponse.ok && usersResponse.ok) {
                        const itemsData = await itemsResponse.json();
                        const usersData = await usersResponse.json();

                        // Check if itemsData is an array and contains pagination info
                        if (Array.isArray(itemsData.items)) {
                            setItems(itemsData.items);
                            setTotalItemsPages(itemsData.totalPages);
                        } else {
                            throw new Error('Items data is not an array');
                        }

                        if (Array.isArray(usersData.customers)) {
                            setUsers(usersData.customers);
                            setTotalCustomersPages(usersData.totalPages);
                        } else {
                            throw new Error('Customers data is not an array');
                        }
                    } else {
                        throw new Error('Failed to fetch items or users');
                    }
                } catch (error) {
                    console.error('Error:', error);
                    setError(error.message);
                }
            };

            fetchItemsAndUsers();
        }
    }, [isAuthorized, itemsPage, customersPage]);

    const handleUserChange = (selected) => {
        setSelectedUser(selected[0]);
    };

    const handleRentalChange = async (index, e) => {
        const { name, value } = e.target;
        const updatedRentals = [...rentals];
        updatedRentals[index][name] = value;

        if (name === 'startDate') {
            if (new Date(value) > new Date(updatedRentals[index].endDate)) {
                updatedRentals[index].endDate = '';
            }
        } else if (name === 'endDate') {
            if (new Date(value) < new Date(updatedRentals[index].startDate)) {
                updatedRentals[index].endDate = updatedRentals[index].startDate;
            }
        }

        if (name === 'quantity') {
            updatedRentals[index].quantity = Math.max(1, parseInt(value));
        }

        setRentals(updatedRentals);

        // Check availability whenever a rental field changes
        checkAvailability(updatedRentals[index], index);
    };

    const checkAvailability = async (rental, index) => {
        const { itemId, startDate, endDate, quantity } = rental;
    
        if (!itemId || !startDate || !endDate || !quantity) {
            return; // Skip checking if any field is missing
        }
    
        try {
            const token = localStorage.getItem('token');
            const response = await fetch(`${API_BASE_URL}/api/rentals/check-availability`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },
                body: JSON.stringify({
                    item_id: parseInt(itemId),
                    start_date: new Date(startDate).toISOString(),
                    end_date: new Date(endDate).toISOString(),
                    quantity: parseInt(quantity)
                })
            });
    
            if (!response.ok) {
                const errorResponse = await response.text(); // Get response text for more details
                throw new Error(`Failed to check availability: ${response.status} ${response.statusText}. ${errorResponse}`);
            }
    
            const result = await response.json();
            const updatedRentals = [...rentals];
            updatedRentals[index].availability = result.available ? 'Available' : 'Not Available';
            setRentals(updatedRentals);
    
            // Update the button disabled state based on availability
            setIsButtonDisabled(updatedRentals.some(rental => rental.availability === 'Not Available'));
        } catch (error) {
            console.error('Error checking availability:', error);
            setError(`Error checking availability: ${error.message}`);
        }
    };

    const addRental = () => {
        setRentals([...rentals, { itemId: '', quantity: 1, startDate: '', endDate: '', price: '', availability: '' }]);
    };

    const removeRental = (index) => {
        const updatedRentals = rentals.filter((_, idx) => idx !== index);
        setRentals(updatedRentals);

        // Update the button disabled state
        setIsButtonDisabled(updatedRentals.some(rental => rental.availability === 'Not Available'));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (!selectedUser) {
                throw new Error('Please select a user');
            }
    
            const rentalIds = [];
            const promises = rentals.map(async (rental) => {
                if (!rental.itemId || !rental.quantity || !rental.startDate || !rental.endDate) {
                    throw new Error('Please fill out all fields');
                }
    
                const formattedStartDate = new Date(rental.startDate).toISOString();
                const formattedEndDate = new Date(rental.endDate).toISOString();
                const itemPrice = parseFloat(rental.price); // Ensure price is derived from rental object
    
                const token = localStorage.getItem('token');
                const response = await fetch(`${API_BASE_URL}/api/rentals`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
                    body: JSON.stringify({
                        item_id: parseInt(rental.itemId),
                        user_id: parseInt(selectedUser.user_id),
                        start_date: formattedStartDate,
                        end_date: formattedEndDate,
                        quantity: parseInt(rental.quantity),
                        item_price: itemPrice // Use price from rental object
                    })
                });
    
                if (!response.ok) {
                    throw new Error('Failed to submit rental');
                }
    
                const result = await response.json();
                rentalIds.push(result.rentalId); // Collect rental IDs here
            });
    
            await Promise.all(promises);
            navigate('/success', {
                state: {
                    rentalIds,
                    totalPrice: calculateTotalPrice()
                }
            });
        } catch (error) {
            console.error('Error submitting rentals:', error);
            setError(error.message);
        }
    };
    
    
    const calculateTotalPrice = () => {
        return rentals.reduce((total, rental) => {
            if (rental && rental.price && rental.quantity && rental.startDate && rental.endDate) {
                const price = parseFloat(rental.price);
                const quantity = parseInt(rental.quantity);
                const numberOfDays = Math.ceil((new Date(rental.endDate) - new Date(rental.startDate)) / (1000 * 60 * 60 * 24));
                return total + (price * quantity * numberOfDays);
            }
            return total;
        }, 0).toFixed(2);
    };


    const calculateTotalPriceForRental = (rental) => {
        const price = parseFloat(rental.price || 0);
        const quantity = parseInt(rental.quantity) || 1;
        const numberOfDays = Math.ceil((new Date(rental.endDate) - new Date(rental.startDate)) / (1000 * 60 * 60 * 24));
        return (price * quantity * numberOfDays).toFixed(2);
    };
    const calculateNumberOfDays = (startDate, endDate) => {
        if (!startDate || !endDate) return 0;
        const start = new Date(startDate);
        const end = new Date(endDate);
        return Math.ceil((end - start) / (1000 * 60 * 60 * 24));
    };

    const profilePhotoPath = selectedUser?.photo_path ? selectedUser.photo_path.replace('/home/emirupzd/public_html/', '').replace(/\\/g, '/') : null;
    const profilePhotoUrl = profilePhotoPath ? `https://emiruproduction.com/${profilePhotoPath}` : null;
    return (
        <div className="rent-item-container">
            <div className="rent-item-form">
                <h2>Rent Item</h2>
                <Form onSubmit={handleSubmit}>
                    <Row className="mb-3">
                    <Col md={6}>
                            <Form.Group controlId="formUser">
                                <Form.Label>Select User</Form.Label>
                                <Typeahead
                                    id="user-select"
                                   
                                    labelKey="user_name"
                                    options={users}
                                    onChange={handleUserChange}
                                    placeholder="Select a user by name..."
                                    style={{ 
                                        '--rbt-menu-max-height': '20px', /* Adjust height as needed */
                                        '--rbt-menu-overflow-y': 'auto'
                                    }}
                                />
                            </Form.Group>
                            <Form.Group controlId="formUserPhone">
                                <Form.Label>Select User</Form.Label>
                                <Typeahead
                                    id="user-select-phone"
                                    className="scrollable-dropdown"
                                    labelKey="user_phone"
                                    options={users}
                                    onChange={handleUserChange}
                                    placeholder="Select a user by phone..."
                                />
                            </Form.Group>
                        </Col>
                        
                        <Col>
      {selectedUser && (
        // Compute the profile photo URL
 
        <div className="detailplace">
         <Card>
  <Card.Body>
    <Card.Title>User Details</Card.Title>
    <div className="d-flex align-items-start">
      <div className="text-container">
        <p><strong>Name:</strong> {selectedUser.user_name}</p>
        <p><strong>Phone:</strong> {selectedUser.user_phone}</p>
      </div>
      <div className="image-card">
        {profilePhotoUrl ? (
          <img
            src={profilePhotoUrl}
            alt="Profile Photo"
            style={{ width: '100px', height: 'auto' }}
          />
        ) : (
          <span></span>
        )}
      </div>
    </div>
  </Card.Body>
</Card>

        </div>
      )}
    </Col>
                        <Col md={12}>
                            <div className="error-message-container">
                                {error && <div className="error-message">{error}</div>}
                            </div>
                        </Col>
                    </Row>
                    {rentals.map((rental, index) => (
                        <Card key={index} className="mb-4">
                            <Card.Body>
                                <Row>
                                    <Col md={4}>
                                    <Form.Group controlId={`item-${index}`}>
                                                            <Form.Label>Item</Form.Label>
                                                            <div style={{ maxHeight: '1px', overflowY: 'auto' }}></div>
                                                            <Form.Control
                                                                as="select"
                                                                name="itemId"
                                                                value={rental.itemId}
                                                                
                                                                onChange={(e) => handleRentalChange(index, e)}
                                                            >
                                                                <option value="">Select Item</option>
                                                                {items.map((item) => (
                                                                    <option key={item.item_id} value={item.item_id}>
                                                                        {item.item_name}
                                                                    </option>
                                                                ))}

                                                                
                                                            </Form.Control>
                                                            
                                                            </Form.Group>
                                    </Col>
                                    <Col md={4}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Quantity</Form.Label>
                                            <Form.Control
                                                type="number"
                                                name="quantity"
                                                value={rental.quantity}
                                                onChange={(e) => handleRentalChange(index, e)}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={4}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Price</Form.Label>
                                            <Form.Control
                                                type="number"
                                                name="price"
                                                value={rental.price}
                                                onChange={(e) => handleRentalChange(index, e)}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={4}>
                                    <Form.Group controlId={`startDate${index}`}>
                                        <Form.Label>Start Date</Form.Label>
                                        <Form.Control
                                            type="datetime-local"
                                            name="startDate"
                                            value={rental.startDate}
                                            onChange={(e) => handleRentalChange(index, e)}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={4}>
                                    <Form.Group controlId={`endDate${index}`}>
                                        <Form.Label>End Date</Form.Label>
                                        <Form.Control
                                            type="datetime-local"
                                            name="endDate"
                                            value={rental.endDate}
                                            onChange={(e) => handleRentalChange(index, e)}
                                        />
                                    </Form.Group>
                                </Col>
                                    <Col>
                                <Form.Group controlId={`formAvailability${index}`}>
                                    <Form.Label>Availability</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="availability"
                                        value={rental.availability}
                                        readOnly
                                    />
                                </Form.Group>
                            </Col>

                                </Row>
                                <Row>
                                <div>Number of Days: {calculateNumberOfDays(rental.startDate, rental.endDate)}</div>
                                </Row>
                                <Row>
                                Total Price: {calculateTotalPriceForRental(rental)} birr
                                </Row>
                                <div className="text-center">
                                    <Button variant="danger" onClick={() => removeRental(index)} className="me-2">Remove</Button>
                                </div>
                            </Card.Body>
                        </Card>
                    ))}
                    <div className="text-center mb-4">
                        <Button variant="secondary" onClick={addRental} className="me-2">Add Another Rental</Button>
                        <Button type="submit" variant="primary" disabled={isButtonDisabled}>Submit</Button>
                    </div>
                    <Card>
                        <Card.Body>
                            <Card.Title>Total Price</Card.Title>
                            <Card.Text>{`${calculateTotalPrice()} birr`}</Card.Text>
                        </Card.Body>
                    </Card>
                </Form>
                
            </div>
        </div>
    );
};

export default RentItem;
