import React, { useState, useEffect } from "react";
import { Bar, Pie } from "react-chartjs-2";
import Card from "react-bootstrap/Card";
import DatePicker from "react-datepicker";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  BarController,
} from "chart.js";
import "react-datepicker/dist/react-datepicker.css";
import "./Analytic.css";
import { useNavigate } from 'react-router-dom';


// Register Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  BarController
);

 

const Analytic = () => {
  const isAuthenticated = !!localStorage.getItem("token");
  const userEmail = localStorage.getItem("email");
  const navigate = useNavigate(); // Move useNavigate inside the component

  const handlePaymentDetailClick = () => {
    navigate("/payment");
  };


  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        label: "Item Quantities",
        data: [],
        backgroundColor: [
          "rgba(255, 165, 0, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "rgba(255, 165, 0, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
  });

  const [customerPieData, setCustomerPieData] = useState({
    labels: [],
    datasets: [
      {
        label: "Customer Rentals Today",
        data: [],
        backgroundColor: [
          "rgba(75, 192, 192, 0.2)",
          "rgba(75, 192, 192, 0.3)",
          "rgba(75, 192, 192, 0.4)",
          "rgba(75, 192, 192, 0.5)",
          "rgba(75, 192, 192, 0.6)",
          "rgba(75, 192, 192, 0.7)",
        ],
        borderColor: [
          "rgba(75, 192, 192, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(75, 192, 192, 1)",
        ],
        borderWidth: 1,
      },
    ],
  });

  const [rentalBarData, setRentalBarData] = useState({
    labels: [],
    datasets: [
      {
        label: "Rentals Over Time",
        data: [],
        backgroundColor: [
          "rgba(54, 162, 235, 0.2)",
          "rgba(54, 162, 235, 0.3)",
          "rgba(54, 162, 235, 0.4)",
          "rgba(54, 162, 235, 0.5)",
          "rgba(54, 162, 235, 0.6)",
          "rgba(54, 162, 235, 0.7)",
        ],
        borderColor: [
          "rgba(54, 162, 235, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(54, 162, 235, 1)",
        ],
        borderWidth: 2,
        fill: true,
      },
    ],
  });

  const [totalQuantities, setTotalQuantities] = useState(0);
  const [totalRentalsToday, setTotalRentalsToday] = useState(0);
  const [totalCustomersToday, setTotalCustomersToday] = useState(0);
   // Set the default startDate to 7 days ago and endDate to today
   // Initialize start and end dates
  const today = new Date();
  const [startDate, setStartDate] = useState(() => {
    const date = new Date();
    date.setDate(date.getDate() - 7);
    return date;
  });
  const [endDate, setEndDate] = useState(() => {
    const date = new Date();
    date.setDate(date.getDate() - 1); // End date should be yesterday
    return date;
  });

  // Handle date range change
  const handleDateRangeChange = (range) => {
    const today = new Date();
    let newStartDate;
    let newEndDate;

    switch (range) {
      case '7days':
        newEndDate = new Date();
        newEndDate.setDate(newEndDate.getDate() - 1); // End date is yesterday
        newStartDate = new Date();
        newStartDate.setDate(newEndDate.getDate() - 6); // Start date is 7 days ago
        break;
      case '15days':
        newEndDate = new Date();
        newEndDate.setDate(newEndDate.getDate() - 1); // End date is yesterday
        newStartDate = new Date();
        newStartDate.setDate(newEndDate.getDate() - 14); // Start date is 15 days ago
        break;
      case '30days':
        newEndDate = new Date();
        newEndDate.setDate(newEndDate.getDate() - 1); // End date is yesterday
        newStartDate = new Date();
        newStartDate.setDate(newEndDate.getDate() - 29); // Start date is 30 days ago
        break;
      case '90days':
        newEndDate = new Date();
        newEndDate.setDate(newEndDate.getDate() - 1); // End date is yesterday
        newStartDate = new Date();
        newStartDate.setDate(newEndDate.getDate() - 89); // Start date is 90 days ago
        break;
      case '365days':
        newEndDate = new Date();
        newEndDate.setDate(newEndDate.getDate() - 1); // End date is yesterday
        newStartDate = new Date();
        newStartDate.setDate(newEndDate.getDate() - 364); // Start date is 365 days ago
        break;
      case 'custom':
        newEndDate = endDate;
        newStartDate = startDate;
        break;
      default:
        newEndDate = today;
        newStartDate = today;
    }

    setStartDate(newStartDate);
    setEndDate(newEndDate);
  };


  const fetchChartData = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch("https://emiruproduction.com/api/rentals/without", {
        headers: { Authorization: `Bearer ${token}` },
      });
  
      if (!response.ok) {
        throw new Error("Failed to fetch rentals");
      }
  
      const { rentals } = await response.json();
  
      const filteredRentals = rentals.filter((rental) => {
        const rentalStartDate = new Date(rental.start_date);
        // Start and end dates are inclusive
        const parseDate = (dateString) => new Date(dateString);

        // Helper function to normalize the date to midnight UTC
        const normalizeDateToStartOfDay = (date) => {
          return new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), 0, 0, 0, 0));
        };
        
        // Helper function to normalize the date to end of the day UTC
        const normalizeDateToEndOfDay = (date) => {
          return new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), 23, 59, 59, 999));
        };
        
        // Convert and normalize start and end dates
        const startDateNormalized = normalizeDateToStartOfDay(parseDate(startDate));
        const endDateNormalized = normalizeDateToEndOfDay(parseDate(endDate));
        
        return rentalStartDate >= startDateNormalized && rentalStartDate <= endDateNormalized;
        

      });
  
      const itemQuantities = {};
      filteredRentals.forEach((rental) => {
        const itemName = rental.item.item_name;
        itemQuantities[itemName] = (itemQuantities[itemName] || 0) + rental.quantity;
      });
  
      const itemNames = Object.keys(itemQuantities);
      const quantities = Object.values(itemQuantities);
      const totalQuantity = quantities.reduce((acc, curr) => acc + curr, 0);
  
      setTotalQuantities(totalQuantity);
  
      setChartData({
        labels: itemNames,
        datasets: [
          {
            label: "Item Quantities",
            data: quantities,
            backgroundColor: itemNames.map(() => "rgba(255, 165, 0, 0.2)"),
            borderColor: itemNames.map(() => "rgba(255, 165, 0, 1)"),
            borderWidth: 1,
          },
        ],
      });
  
      const totalRentals = filteredRentals.length;
      setTotalRentalsToday(totalRentals);
  
      const uniqueCustomers = new Set(
        filteredRentals.map((rental) => rental.customer.user_id)
      ).size;
  
      setTotalCustomersToday(uniqueCustomers);
  
      const customerNames = {};
      filteredRentals.forEach((rental) => {
        const userId = rental.customer.user_id;
        const userName = rental.customer.user_name; // Assuming user_name field is available
        customerNames[userId] = userName;
      });
  
      const customerRentals = {};
      filteredRentals.forEach((rental) => {
        const userId = rental.customer.user_id;
        customerRentals[userId] = (customerRentals[userId] || 0) + 1;
      });
  
      const customerIds = Object.keys(customerRentals);
      const rentalCounts = Object.values(customerRentals);
  
      setCustomerPieData({
        labels: customerIds.map((id) => customerNames[id] || `User ${id}`),
        datasets: [
          {
            label: "Customer Rentals",
            data: rentalCounts,
            backgroundColor: customerIds.map(() => "rgba(75, 192, 192, 0.2)"),
            borderColor: customerIds.map(() => "rgba(75, 192, 192, 1)"),
          },
        ],
      });
  
      const rentalCountsByHour = {};
      filteredRentals.forEach((rental) => {
        const hour = new Date(rental.start_date).getHours();
        rentalCountsByHour[hour] = (rentalCountsByHour[hour] || 0) + 1;
      });
  
      const hours = Array.from({ length: 24 }, (_, i) => i);
      const rentalsPerHour = hours.map((hour) => rentalCountsByHour[hour] || 0);
  
      setRentalBarData({
        labels: hours.map((hour) => {
          const formattedHour = hour % 12 || 12;
          const period = hour < 12 ? "AM" : "PM";
          return `${formattedHour}:00 ${period}`;
        }),
        datasets: [
          {
            label: "Rentals Over Time",
            data: rentalsPerHour,
            backgroundColor: "rgba(54, 162, 235, 0.2)",
            borderColor: "rgba(54, 162, 235, 1)",
            borderWidth: 2,
            fill: true,
          },
        ],
      });
    } catch (error) {
      console.error("Error fetching chart data:", error);
    }
  };
  

  useEffect(() => {
    fetchChartData();
  }, [startDate, endDate]);

  return (
    <div className="container">
      <div className="row mb-4">
        <div className="col-md-6 mb-4">
        <div className="dashboard">
        <h1 className="mb-4">Analytic</h1>
        </div>
            
        <div className="welcome-message">
            select date range
          </div>
              <div className="d-flex">
               
                <div>

                <div className="d-flex">
      <div className="me-2">
        <DatePicker
          selected={startDate}
          onChange={(date) => setStartDate(date)}
          selectsStart
          startDate={startDate}
          endDate={endDate}
          dateFormat="yyyy-MM-dd"
          className="custom-date-picker"
        />
      </div>
      <div>
        <DatePicker
          selected={endDate}
          onChange={(date) => setEndDate(date)}
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          minDate={startDate}
          dateFormat="yyyy-MM-dd"
          className="custom-date-picker"
        />
      </div>
    </div>
                
                </div>
                <div className="date-range-selector">
  <select 
    onChange={(e) => handleDateRangeChange(e.target.value)} 
    defaultValue="7days" 
    className="form-select"
  >
    <option value="7days">Last 7 Days</option>
    <option value="15days">Last 15 Days</option>
    <option value="30days">Last 30 Days</option>
    <option value="90days">Last 90 Days</option>
    <option value="365days">Last 365 Days</option>
  </select>
</div>

              </div>
              
            
         
        </div>
        <Row className="mb-4">
      <Col md={4} sm={12}>
        <Card className="analytics-card">
          <Card.Body>
          <i
                      className="fas fa-user fa-3x mb-3"
                      style={{ color: "#4CAF50" }}
                    ></i>
            <Card.Title>Total Customers</Card.Title>
            <Card.Text>{totalCustomersToday}</Card.Text>
          </Card.Body>
        </Card>
      </Col>
      <Col md={4} sm={12}>
        <Card className="analytics-card">
          <Card.Body>
          <i
                      className="fas fa-box-open fa-3x mb-3"
                      style={{ color: "#FF9800" }}
                    ></i>
            <Card.Title>Total Item Out</Card.Title>
            <Card.Text>{totalQuantities}</Card.Text>
          </Card.Body>
        </Card>
      </Col>
      <Col md={4} sm={12}>
        <Card className="analytics-card">
          <Card.Body>
      
                     <i
                      className="fas fa-users fa-3x mb-3"
                      style={{ color: "#2196F3" }}
                    ></i>
            <Card.Title>Total Rentals</Card.Title>
            <Card.Text>{totalRentalsToday}</Card.Text>
          </Card.Body>
        </Card>
      </Col>
      <Col md={4} sm={12}>
        <Card className="analytics-card" onClick={handlePaymentDetailClick}>
          <Card.Body>
          <i
                      className="fas fa-dollar fa-3x mb-3"
                      style={{ color: "#f542ad" }}
                    ></i>
            <Card.Title>Payment Detail</Card.Title>
            <button type="button" class="btn btn-outline-dark">visit</button>
           
          </Card.Body>
        </Card>
      </Col>
    </Row>


      </div>
      <div className="row">
        
        <div className="col-md-6">
          <Card>
            <Card.Body>
              <Card.Title>Item Quantities</Card.Title>
              <Bar data={chartData} options={{ responsive: true }} />
            </Card.Body>
          </Card>
        </div>
        <div className="col-md-6">
          <Card>
            <Card.Body>
              <Card.Title>Customer Rentals Today</Card.Title>
              <Pie data={customerPieData} options={{ responsive: true }} />
            </Card.Body>
          </Card>
        </div>
        <div className="col-md-6">
          <Card>
            <Card.Body>
              <Card.Title>Rentals Over Time</Card.Title>
              <Bar data={rentalBarData} options={{ responsive: true }} />
            </Card.Body>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default Analytic;
