import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import Header from './pages/header/header'; // Adjust the import path
import Sidebar from './pages/header/side'; // Adjust the import path
import Dashbord from './pages/dashbord/dashbord';
import No_Match from './pages/noMatch/NoMatch';
import PostAdmin from './pages/Admin/PostAdmin';
import AdminList from './pages/Admin/LIstAdmin';
import PostItems from './pages/Items/PostItems';
import ListItems from './pages/Items/LIstItems';
import RentItem from './pages/Rent/RentItem';
import RentalList from './pages/Rent/RentList';
import RentalCategoryDetails from './pages/Rent/RentalCategoryDetails';
import PostUser from './pages/Users/PostUser';
import UserList from './pages/Users/LIstUser';
import LoginPage from './pages/components/LoginPage';
import WelcomePage from './pages/components/WelcomePage';

import './App.css'; // Ensure you have appropriate styles
import Success from './pages/Rent/success';
import Analytic from './pages/analytic/Analytic';
import PaymentSummary from './pages/analytic/Paymentdetail';
import RentalCategories from './pages/analytic/stock';
import Stock from './pages/analytic/stock';

const PrivateRoute = ({ element }) => {
  const isAuthenticated = !!localStorage.getItem('token');
  const location = useLocation();

  if (isAuthenticated) {
    // Redirect authenticated users away from login page
    if (location.pathname === '/login') {
      return <Navigate to="/" />;
    }
    return element;
  } else {
    return <Navigate to="/login" />;
  }
};

const PublicRoute = ({ element }) => {
  const isAuthenticated = !!localStorage.getItem('token');
  const location = useLocation();

  if (isAuthenticated) {
    // Redirect authenticated users away from login page
    if (location.pathname === '/login') {
      return <Navigate to="/" />;
    }
    return <Navigate to="/" />;
  } else {
    return element;
  }
};

function App() {
  return (
    <Router>
      <div className="app-container">
      
        <div className="main-content">
          <Sidebar />
          
          <div className="content-area">
          <Header />
            <Routes>
              <Route path='/login' element={<PublicRoute element={<LoginPage />} />} />
              <Route path='/welcome' element={<PrivateRoute element={<WelcomePage />} />} />
              <Route path='/' element={<PrivateRoute element={<Dashbord />} />} />
              <Route path='/analytic' element={<PrivateRoute element={<Analytic />} />} />
              <Route path='/postAdmin' element={<PrivateRoute element={<PostAdmin />} />} />
              <Route path='/ListAdmin' element={<PrivateRoute element={<AdminList />} />} />
              <Route path='/postItems' element={<PrivateRoute element={<PostItems />} />} />
              <Route path='/listItems' element={<PrivateRoute element={<ListItems />} />} />
              <Route path='/PostRent' element={<PrivateRoute element={<RentItem />} />} />
              <Route path='/listRent' element={<PrivateRoute element={<RentalList />} />} />
              <Route path='/success' element={<PrivateRoute element={<Success />} />} />
              <Route path='/listRentcat' element={<PrivateRoute element={<RentalCategoryDetails/>} />} />
              <Route path='/PostUser' element={<PrivateRoute element={<PostUser />} />} />
              <Route path='/listUser' element={<PrivateRoute element={<UserList />} />} />
              <Route path='/payment' element={<PrivateRoute element={<PaymentSummary />} />} />
              <Route path='/stock' element={<PrivateRoute element={<Stock />} />} />


              

              <Route path='*' element={<PrivateRoute element={<No_Match />} />} />
            </Routes>
          </div>
        </div>
      </div>
    </Router>
  );
}

export default App;
