import React, { useEffect, useState } from 'react';
import { Card, Row, Col, Spinner, Alert } from 'react-bootstrap';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';

const Stock = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const itemMap = new Map(); // Track items and accumulate quantity and return_quantity

  useEffect(() => {
    // Fetch data from the API
    const fetchData = async () => {
      try {
        const response = await fetch('https://emiruproduction.com/api/rental-categories/rental-categories/without');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const result = await response.json();
        setData(result.data); // Assuming `data` is the key in the response
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return (
      <div className="text-center my-5">
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
        <p>Loading stock data...</p>
      </div>
    );
  }

  if (error) {
    return (
      <Alert variant="danger" className="my-4">
        Error: {error.message}
      </Alert>
    );
  }

  // Process data to accumulate quantity and return_quantity for each item
  data.forEach(category => {
    category.rentals.forEach(rental => {
      const { item_id, item_name, item_quantity, file_path } = rental.item; // Destructure file_path
      const rental_quantity = rental.quantity;
      const return_quantity = rental.return_quantity;

      if (itemMap.has(item_id)) {
        // Accumulate quantities if item already exists
        const existingItem = itemMap.get(item_id);
        existingItem.total_quantity += rental_quantity;
        existingItem.return_quantity += return_quantity;
        itemMap.set(item_id, existingItem);
      } else {
        // Add new item to the map
        itemMap.set(item_id, {
          item_name,
          item_quantity,
          total_quantity: rental_quantity,
          return_quantity,
          file_path, // Store the file_path
        });
      }
    });
  });

  return (
    <div className="container my-5">
      <h1 className="mb-4 text-center">Stock Overview</h1>
      <Row>
        {[...itemMap.values()].map((item, index) => {
          const current_quantity = item.item_quantity - (item.total_quantity - item.return_quantity);
          const not_returned_quantity = item.item_quantity - current_quantity;
          const imageUrl = item.file_path 
            ? `https://emiruproduction.com/${item.file_path.replace('/home/emirupzd/public_html/', '').replace(/\\/g, '/')}` 
            : 'path_to_placeholder_image'; // Replace with a placeholder image URL or text

          const quantitiesMatch = current_quantity === item.item_quantity;

          return (
            <Col md={6} key={index}>
              <Card className={`mb-4 shadow-sm ${quantitiesMatch ? 'border-success' : 'border-danger'}`}>
                <Card.Body>
                  <Row className="align-items-center">
                    <Col md={8}>
                      <Card.Title className="text-primary">{item.item_name}</Card.Title>
                      <Card.Text>
                        <div className={`p-2 ${quantitiesMatch ? 'bg-success text-white' : 'bg-danger text-white'} rounded`}>
                          <strong>Current Quantity:</strong> 
                          <span> {current_quantity}</span>
                          {quantitiesMatch ? (
                            <FaCheckCircle className="ml-2" />
                          ) : (
                            <FaTimesCircle className="ml-2" />
                          )}
                        </div>
                        <div className="p-2 mt-2 bg-light rounded">
                          <strong>Available Quantity:</strong> 
                          <span className="text-info"> {item.item_quantity}</span>
                        </div>
                        <div className={`p-2 mt-2 ${not_returned_quantity > 0 ? 'bg-warning' : 'bg-light'} rounded`}>
                          <strong>Not Returned:</strong> 
                          <span> {not_returned_quantity}</span>
                        </div>
                      </Card.Text>
                    </Col>
                    <Col md={4} className="text-right">
                      {item.file_path ? (
                        <img 
                          src={imageUrl} 
                          alt={item.item_name}
                          style={{ width: '150px', height: 'auto' }} 
                          className="img-fluid rounded"
                        />
                      ) : (
                        <p>No image available</p>
                      )}
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          );
        })}
      </Row>
    </div>
  );
};

export default Stock;
