import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Table from 'react-bootstrap/Table';
import Container from 'react-bootstrap/Container';
import Pagination from 'react-bootstrap/Pagination';
import Button from 'react-bootstrap/Button';
import { ButtonGroup, Alert, Spinner, Modal, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

function UserList() {
  const [customers, setCustomers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5);
  const [totalPages, setTotalPages] = useState(1);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [editCustomer, setEditCustomer] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const checkAuthorization = () => {
      const token = localStorage.getItem('token');
      if (token) {
        setIsAuthorized(true);
      } else {
        setIsAuthorized(false);
        navigate('/unauthorized'); // Redirect if not authorized
      }
    };

    checkAuthorization();
  }, [navigate]);

  useEffect(() => {
    if (isAuthorized) {
      fetchCustomers(currentPage, itemsPerPage);
    }
  }, [currentPage, itemsPerPage, searchKeyword, isAuthorized]);

  const fetchCustomers = async (page, size) => {
    setLoading(true);
    try {
      const token = localStorage.getItem('token');
      let url = `https://emiruproduction.com/api/customers?page=${page}&size=${size}`;
      if (searchKeyword) {
        url += `&search=${searchKeyword}`;
      }

      const response = await axios.get(url, {
        headers: { Authorization: `Bearer ${token}` },
      });

      // Sort customers by ID in descending order
      const sortedCustomers = response.data.customers.sort(
        (a, b) => b.user_id - a.user_id
      );

      setCustomers(sortedCustomers);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      if (error.response) {
        const { status, data } = error.response;
        if (status === 500) {
          setError(data.message || 'An unexpected error occurred. Please try again.');
        } else {
          setError('Failed to fetch customers. Please try again.');
        }
      } else {
        setError('Network error. Please check your connection.');
      }
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = () => {
    setCurrentPage(1);
    fetchCustomers(1, itemsPerPage);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    fetchCustomers(pageNumber, itemsPerPage);
  };

  const handleDelete = async (id) => {
    const confirmed = window.confirm('Are you sure you want to delete this customer?');
    if (!confirmed) return;

    try {
      const token = localStorage.getItem('token');
      await axios.delete(`https://emiruproduction.com/api/customers/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      fetchCustomers(currentPage, itemsPerPage);
    } catch (error) {
      setError('Failed to delete customer. Please try again.');
    }
  };

  const handleEdit = (customer) => {
    setEditCustomer(customer);
    setShowModal(true);
  };

  const handleUpdate = async () => {
    try {
      const token = localStorage.getItem('token');
      const {
        user_id,
        user_name,
        user_email,
        user_phone,
        user_city,
        user_house_number,
        user_sub_city,
        user_woreda,
      } = editCustomer;

      const formData = new FormData();
      formData.append('user_name', user_name);
      formData.append('user_email', user_email);
      formData.append('user_phone', user_phone);
      formData.append('user_city', user_city);
      formData.append('user_house_number', user_house_number);
      formData.append('user_sub_city', user_sub_city);
      formData.append('user_woreda', user_woreda);

      // Append new files only if they are selected
      const idPhotoFile = document.getElementById('idPhotoInput').files[0];
      const profilePhotoFile = document.getElementById('profilePhotoInput').files[0];

      if (idPhotoFile) {
        formData.append('id_photo', idPhotoFile);
      }

      if (profilePhotoFile) {
        formData.append('photo', profilePhotoFile);
      }

      await axios.put(`https://emiruproduction.com/api/customers/${user_id}`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });

      setShowModal(false);
      fetchCustomers(currentPage, itemsPerPage);
    } catch (error) {
      setError('Failed to update customer. Please try again.');
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditCustomer({ ...editCustomer, [name]: value });
  };

  if (!isAuthorized) {
    return null; // Or a loading spinner if you want to show a spinner while checking authorization
  }

  return (
    <Container>
      <h5>Customer List</h5>
      <div className="mb-3 d-flex flex-column align-items-start">
        <input
          type="text"
          placeholder="Search by name"
          value={searchKeyword}
          onChange={(e) => setSearchKeyword(e.target.value)}
          className="form-control mb-2"
        />
        <Button onClick={handleSearch} className="mb-2">
          Search
        </Button>
      </div>
      {error && <Alert variant="danger" className="new-alert">{error}</Alert>}
      {loading ? (
        <Spinner animation="border" size="sm" />
      ) : (
        <>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>User Name</th>
                <th>User Email</th>
                <th>Phone</th>
                <th>City</th>
                <th>House Number</th>
                <th>Sub City</th>
                <th>Woreda</th>
                <th>ID Photo</th>
                <th>Profile Photo</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {customers.length > 0 ? (
                customers.map((customer) => {
                  // Format paths to use forward slashes and ensure they are valid URLs
                  const idPhotoPath = customer.id_photo_path
                    ? customer.id_photo_path.replace('/home/emirupzd/public_html/', '').replace(/\\/g, '/')
                    : null;
                  const profilePhotoPath = customer.photo_path
                    ? customer.photo_path.replace('/home/emirupzd/public_html/', '').replace(/\\/g, '/')
                    : null;
                   
                  const idPhotoUrl = idPhotoPath
                    ? `https://emiruproduction.com/${idPhotoPath}`
                    : null;
                  const profilePhotoUrl = profilePhotoPath
                    ? `https://emiruproduction.com/${profilePhotoPath}`
                    : null;

                  return (
                    <tr key={customer.user_id}>
                      <td>{customer.user_name}</td>
                      <td>{customer.user_email}</td>
                      <td>{customer.user_phone}</td>
                      <td>{customer.user_city}</td>
                      <td>{customer.user_house_number}</td>
                      <td>{customer.user_sub_city}</td>
                      <td>{customer.user_woreda}</td>
                      <td>
                        {idPhotoUrl ? (
                          <img
                            src={idPhotoUrl}
                            alt="ID Photo"
                            style={{ width: '100px', height: 'auto' }}
                          />
                        ) : (
                          <span>No ID Photo</span>
                        )}
                      </td>
                      <td>
                        {profilePhotoUrl ? (
                          <img
                            src={profilePhotoUrl}
                            alt="Profile Photo"
                            style={{ width: '100px', height: 'auto' }}
                          />
                        ) : (
                          <span>No Profile Photo</span>
                        )}
                      </td>
                      <td>
                        <ButtonGroup>
                          <Button
                            variant="warning"
                            onClick={() => handleEdit(customer)}
                          >
                            Edit
                          </Button>
                          <Button
                            variant="danger"
                            onClick={() => handleDelete(customer.user_id)}
                          >
                            Delete
                          </Button>
                        </ButtonGroup>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan="10">No customers found.</td>
                </tr>
              )}
            </tbody>
          </Table>
          <Pagination>
            <Pagination.Prev
              onClick={() =>
                currentPage > 1 && handlePageChange(currentPage - 1)
              }
              disabled={currentPage === 1}
            />
            {[...Array(totalPages).keys()].map((page) => (
              <Pagination.Item
                key={page + 1}
                active={page + 1 === currentPage}
                onClick={() => handlePageChange(page + 1)}
              >
                {page + 1}
              </Pagination.Item>
            ))}
            <Pagination.Next
              onClick={() =>
                currentPage < totalPages && handlePageChange(currentPage + 1)
              }
              disabled={currentPage === totalPages}
            />
          </Pagination>
        </>
      )}

      {/* Edit Customer Modal */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Customer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {editCustomer && (
            <Form>
              <Form.Group>
                <Form.Label>User Name</Form.Label>
                <Form.Control
                  type="text"
                  name="user_name"
                  value={editCustomer.user_name}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>User Email</Form.Label>
                <Form.Control
                  type="email"
                  name="user_email"
                  value={editCustomer.user_email}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>User Phone</Form.Label>
                <Form.Control
                  type="text"
                  name="user_phone"
                  value={editCustomer.user_phone}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>City</Form.Label>
                <Form.Control
                  type="text"
                  name="user_city"
                  value={editCustomer.user_city}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>House Number</Form.Label>
                <Form.Control
                  type="text"
                  name="user_house_number"
                  value={editCustomer.user_house_number}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Sub City</Form.Label>
                <Form.Control
                  type="text"
                  name="user_sub_city"
                  value={editCustomer.user_sub_city}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Woreda</Form.Label>
                <Form.Control
                  type="text"
                  name="user_woreda"
                  value={editCustomer.user_woreda}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>ID Photo</Form.Label>
                <Form.Control type="file" id="idPhotoInput" />
              </Form.Group>
              <Form.Group>
                <Form.Label>Profile Photo</Form.Label>
                <Form.Control type="file" id="profilePhotoInput" />
              </Form.Group>
            </Form>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleUpdate}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default UserList;
