import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Table from 'react-bootstrap/Table';
import Container from 'react-bootstrap/Container';
import Pagination from 'react-bootstrap/Pagination';
import Button from 'react-bootstrap/Button';
import { useNavigate } from 'react-router-dom';
import { Alert, Spinner, Modal, Form } from 'react-bootstrap';

function RentalList() {
  const [rentals, setRentals] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5);
  const [totalPages, setTotalPages] = useState(1);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [showEditModal, setShowEditModal] = useState(false);
  const [currentRental, setCurrentRental] = useState(null);
  const [newQuantity, setNewQuantity] = useState('');
  const [newStartDate, setNewStartDate] = useState('');
  const [newEndDate, setNewEndDate] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const checkAuthorization = () => {
      const token = localStorage.getItem('token');
      if (token) {
        setIsAuthorized(true);
      } else {
        setIsAuthorized(false);
        navigate('/unauthorized');
      }
    };

    checkAuthorization();
  }, [navigate]);

  useEffect(() => {
    if (isAuthorized) {
      fetchRentals(currentPage, itemsPerPage);
    }
  }, [currentPage, itemsPerPage, searchKeyword, isAuthorized]);

  // Fetch rentals data
  const fetchRentals = async (page, size) => {
    setLoading(true);
    try {
      const token = localStorage.getItem('token');
      let url = `https://emiruproduction.com/api/rentals?page=${page}&size=${size}`;
      if (searchKeyword) {
        url += `&search=${searchKeyword}`;
      }

      const response = await axios.get(url, {
        headers: { Authorization: `Bearer ${token}` },
      });

      setRentals(response.data.rentals || []); // Ensure rentals is always an array
      setTotalPages(response.data.totalPages || 1);
    } catch (error) {
      if (error.response) {
        const { status, data } = error.response;
        if (status === 500) {
          setError(data.message || 'An unexpected error occurred. Please try again.');
        } else {
          setError('Failed to fetch rentals. Please try again.');
        }
      } else {
        setError('Network error. Please check your connection.');
      }
    } finally {
      setLoading(false);
    }
  };

  // Handle rental updates
  const handleUpdate = async () => {
    if (!currentRental) return;

    const token = localStorage.getItem('token');
    const url = `https://emiruproduction.com/api/rentals/${currentRental.rental_id}`;

    try {
      await axios.put(
        url,
        {
          quantity: newQuantity,
          start_date: newStartDate,
          end_date: newEndDate,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      setShowEditModal(false);
      fetchRentals(currentPage, itemsPerPage);
    } catch (error) {
      setError('Failed to update rental. Please try again.');
    }
  };

  // Handle rental deletion
  const handleDelete = async (rentalId) => {
    const token = localStorage.getItem('token');
    const url = `https://emiruproduction.com/api/rentals/${rentalId}`;

    try {
      await axios.delete(url, {
        headers: { Authorization: `Bearer ${token}` },
      });

      fetchRentals(currentPage, itemsPerPage);
    } catch (error) {
      setError('Failed to delete rental. Please try again.');
    }
  };

  // Handle rental editing
  const handleEdit = (rental) => {
    setCurrentRental(rental);
    setNewQuantity(rental?.quantity || '');
    setNewStartDate(rental?.start_date ? rental.start_date.split('T')[0] : '');
    setNewEndDate(rental?.end_date ? rental.end_date.split('T')[0] : '');
    setShowEditModal(true);
  };

  // Handle search functionality
  const handleSearch = () => {
    setCurrentPage(1);
    fetchRentals(1, itemsPerPage);
  };

  // Handle page change for pagination
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    fetchRentals(pageNumber, itemsPerPage);
  };

  if (!isAuthorized) {
    return null; // Or a loading spinner if you want to show a spinner while checking authorization
  }

  return (
    <Container>
      <h5>Rental List</h5>
      <div className="mb-3 d-flex flex-column align-items-start">
        <input
          type="text"
          placeholder="Search by name"
          value={searchKeyword}
          onChange={(e) => setSearchKeyword(e.target.value)}
          className="form-control mb-2"
        />
        <Button onClick={handleSearch} className="mb-2">
          Search
        </Button>
      </div>
      {error && <Alert variant="danger" className="new-alert">{error}</Alert>}
      {loading ? (
        <Spinner animation="border" size="sm" />
      ) : (
        <>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Rental ID</th>
                <th>User Name</th>
                <th>Item Name</th>
                <th>Quantity</th>
                <th>Start Date</th>
                <th>End Date</th>
                <th>Total Price</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {rentals && rentals.length > 0 ? (
                rentals.map((rental, index) => (
                  <tr key={rental?.rental_id || index}>
                    <td>{rental?.rental_id || 'N/A'}</td>
                    <td>{rental?.customer?.user_name || 'N/A'}</td>
                    <td>{rental?.item?.item_name || 'N/A'}</td>
                    <td>{rental?.quantity || 'N/A'}</td>
                    <td>{rental?.start_date || 'N/A'}</td>
                    <td>{rental?.end_date || 'N/A'}</td>
                    <td>
                      {(
                        (
                          (new Date(rental?.end_date) - new Date(rental?.start_date)) /
                          (1000 * 60 * 60 * 24) +
                          1
                        ) *
                        parseFloat(rental?.item?.price || 0) *
                        (parseInt(rental?.quantity) || 1)
                      ).toFixed(2)}
                    </td>
                    <td>
                      <div className="d-flex justify-content-start">
                        <Button
                          variant="primary"
                          size="sm"
                          className="me-2" // Bootstrap class for margin-end
                          onClick={() => handleEdit(rental)}
                        >
                          Edit
                        </Button>
                        <Button
                          variant="danger"
                          size="sm"
                          onClick={() => handleDelete(rental?.rental_id)}
                        >
                          Delete
                        </Button>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="8" className="text-center">
                    No rentals found
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
          <div className="d-flex justify-content-center mt-3">
            <Pagination>
              <Pagination.Prev
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              />
              {[...Array(totalPages)].map((_, index) => (
                <Pagination.Item
                  key={index}
                  active={index + 1 === currentPage}
                  onClick={() => handlePageChange(index + 1)}
                >
                  {index + 1}
                </Pagination.Item>
              ))}
              <Pagination.Next
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
              />
            </Pagination>
          </div>
        </>
      )}

      {/* Edit Modal */}
      <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Rental</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Quantity</Form.Label>
              <Form.Control
                type="number"
                value={newQuantity}
                onChange={(e) => setNewQuantity(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Start Date</Form.Label>
              <Form.Control
                type="date"
                value={newStartDate}
                onChange={(e) => setNewStartDate(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>End Date</Form.Label>
              <Form.Control
                type="date"
                value={newEndDate}
                onChange={(e) => setNewEndDate(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowEditModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleUpdate}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default RentalList;
