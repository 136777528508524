import React, { useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import './PostItems.css';

function PostItems() {
  const [formData, setFormData] = useState({
    item_name: "",
    item_description: "",
    item_price: "",
    item_quantity: "", // Added item_quantity
    file: null,
  });
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  // Function to handle form input changes
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Function to handle file input change
  const handleFileChange = (event) => {
    setFormData({
      ...formData,
      file: event.target.files[0],
    });
  };

  // Function to submit form data
  const handleSubmit = async (event) => {
    event.preventDefault();

    // Retrieve the token from local storage
    const token = localStorage.getItem('token');

    // Create FormData object for file upload
    const formDataForUpload = new FormData();
    formDataForUpload.append('item_name', formData.item_name);
    formDataForUpload.append('item_description', formData.item_description);
    formDataForUpload.append('item_price', formData.item_price);
    formDataForUpload.append('item_quantity', formData.item_quantity); // Append item_quantity
    formDataForUpload.append('file', formData.file); // Ensure this matches server-side Multer config

    try {
      // Make the POST request with authorization
      const response = await fetch('https://emiruproduction.com/api/items', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`, // Include the token in headers
        },
        body: formDataForUpload,
      });

      // Check if the response is ok
      if (response.ok) {
        setShowSuccessModal(true);
        // Reset form data
        setFormData({
          item_name: "",
          item_description: "",
          item_price: "",
          item_quantity: "", // Reset item_quantity
          file: null
        });
      } else {
        throw new Error('Failed to add item');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <>
      <div className="center-form">
        <h5>Add New Items</h5>
        <Form onSubmit={handleSubmit}>
          {/* Item Name */}
          <Form.Group className="mb-3" controlId="formBasicName">
            <Form.Label>Item Name</Form.Label>
            <Form.Control
              type="text"
              name="item_name"
              value={formData.item_name}
              onChange={handleInputChange}
              placeholder="Enter Item Name"
              required // HTML5 required attribute
            />
          </Form.Group>

          {/* Item Description */}
          <Form.Group className="mb-3" controlId="formBasicDescription">
            <Form.Label>Item Description</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              name="item_description"
              value={formData.item_description}
              onChange={handleInputChange}
              placeholder="Enter Item Description"
              required // HTML5 required attribute
            />
          </Form.Group>

          {/* Item Price */}
          <Form.Group className="mb-3" controlId="formBasicPrice">
            <Form.Label>Item Price</Form.Label>
            <Form.Control
              type="number"
              step="0.01"
              name="item_price"
              value={formData.item_price}
              onChange={handleInputChange}
              placeholder="Enter Item Price"
              required // HTML5 required attribute
            />
          </Form.Group>

          {/* Item Quantity */}
          <Form.Group className="mb-3" controlId="formBasicQuantity">
            <Form.Label>Item Quantity</Form.Label>
            <Form.Control
              type="number"
              name="item_quantity"
              value={formData.item_quantity}
              onChange={handleInputChange}
              placeholder="Enter Item Quantity"
              required // HTML5 required attribute
            />
          </Form.Group>

          {/* File Upload */}
          <Form.Group className="mb-3" controlId="formBasicFile">
            <Form.Label>Item Image</Form.Label>
            <Form.Control
              type="file"
              name="file"
              onChange={handleFileChange}
              accept="image/*" // Accept only images
              required // HTML5 required attribute
            />
          </Form.Group>

          {/* Submit Button */}
          <Button variant="primary" type="submit">
            Add Item
          </Button>
        </Form>
      </div>

      {/* Success Modal */}
      <Modal show={showSuccessModal} onHide={() => setShowSuccessModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Success</Modal.Title>
        </Modal.Header>
        <Modal.Body>Item added successfully!</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowSuccessModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default PostItems;
